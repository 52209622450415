// import * as style from './Contact.module.scss'
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Section from '../Section/Section';
import FadeIn from '../FadeIn/FadeIn';

export default function Contact({ delay }) {
  const data = useStaticQuery(graphql`
    query {
      sanityContact {
        email
        contact_text
      }
    }
  `);

  const { contact_text: contactText } = data.sanityContact;
  const { email } = data.sanityContact;

  return (
    <Section>
      <FadeIn delay={delay}>
        <h2>Contact</h2>
        <p>{contactText}</p>
        <p>{email}</p>
      </FadeIn>
    </Section>
  );
}
