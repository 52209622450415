import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as style from './Media.module.scss';
import Section from '../Section/Section';
import FadeIn from '../FadeIn/FadeIn';

function MediaItem({ item }) {
  return (
    <a className={style.item} href={item.link} rel="noopener noreferrer" target="_blank">
      <GatsbyImage className={style.image} image={item.image.asset.gatsbyImageData} alt={item.alt} />
      <p className={style.caption}>{item.caption}</p>
    </a>
  );
}

export default function Media({ delay }) {
  const data = useStaticQuery(graphql`
    query {
      allSanityMedia {
        nodes {
          images {
            alt
            link
            caption
            image {
              asset {
                gatsbyImageData(placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    }
  `);

  const media = data.allSanityMedia.nodes[0].images;

  return (
    <Section>
      <FadeIn delay={delay}>
        <h2>Links</h2>
        <div className={style.grid}>
          {media.map((item, i) => (
            <MediaItem key={i} item={item} />
          ))}
        </div>
      </FadeIn>
    </Section>
  );
}
