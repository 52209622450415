import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as style from './Category.module.scss';

function ProjectTile({ project }) {
  return (
    <a href={project.link} target="_blank" className={style.project} rel="noreferrer noopener">
      <GatsbyImage
        className={style.artwork}
        image={project.image.asset.gatsbyImageData}
        alt={`Album art for ${project.title} by ${project.artist}`}
      />
      <p className={style.title}>
        {project.artist} - {project.title} [{project.year}]
      </p>
    </a>
  );
}

export default function Category({ category }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className={style.container}>
        <button
          type="button"
          onClick={() => setIsOpen((prev) => !prev)}
          className={[style.toggleProjects, isOpen ? style.open : null].join(' ')}
        >
          <span>{category.category}</span>
          <div className={style.plusMinus}>
            <span className={style.minus}>-</span>
            <span className={style.plus}>+</span>
          </div>
        </button>
        {isOpen && (
          <div className={style.projects}>
            {category.projects.map((project, i) => (
              <ProjectTile key={i} project={project} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}
