// extracted by mini-css-extract-plugin
export var container = "Category-module--container--3Sg0i";
export var toggleProjects = "Category-module--toggleProjects--3Px2N";
export var plusMinus = "Category-module--plusMinus--CT10z";
export var minus = "Category-module--minus--oNkWM";
export var plus = "Category-module--plus--3iF6O";
export var open = "Category-module--open--cUmLI";
export var projects = "Category-module--projects--3fp-9";
export var project = "Category-module--project--2IdTn";
export var title = "Category-module--title--36M4K";
export var artwork = "Category-module--artwork--2dfk_";