import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Category from '../Category/Category';
import * as style from './IAmA.module.scss';
import Section from '../Section/Section';
import FadeIn from '../FadeIn/FadeIn';

export default function IAmA({ delay }) {
  const data = useStaticQuery(graphql`
    query {
      allSanityIama {
        nodes {
          category
        }
      }
      allSanityProject {
        nodes {
          artist
          credits {
            category
          }
          title
          link
          year
          image {
            asset {
              gatsbyImageData(placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  `);

  const categories = data.allSanityIama.nodes;
  const projects = data.allSanityProject.nodes;

  const categoriesArray = [];

  // Create an object for each category in the categoriesArray
  for (const category of categories) {
    categoriesArray.push({ category: category.category, projects: [] });
  }

  // Populate each category object with its projects
  for (const categoryObject of categoriesArray) {
    for (const project of projects) {
      for (const credit of project.credits) {
        if (credit.category === categoryObject.category) {
          categoryObject.projects.push(project);
        }
      }
    }
  }

  // Sort each project array by year

  return (
    <Section>
      <FadeIn delay={delay}>
        <h2 className={style.title}>I am a...</h2>
        {categoriesArray.map((category, i) => (
          <Category key={i} category={category} />
        ))}
      </FadeIn>
    </Section>
  );
}
